import { Box, Button, Typography ,keyframes} from "@mui/material";
import { landingPage_styles } from "./landing_page";
import intro_image from "./landing_assets/Mask group.svg"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"
import {TranslateText,TranslateSpanText } from "../utils/googletranslator";
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
export function Introduction() {
    const language= useSelector(state=>state.user.language)
    const [translatedText,setTranslatedText]=useState("")
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ maxWidth: 900  });
    const navigate = useNavigate()
    const shine = keyframes`
  0% {
    transform: translateX(0px) rotate(25deg);
  }
  100% {
    transform: translateX(2000px) rotate(25deg);
  }
`;

const boxStyle = {
  background: "#00C2FF",
  height: "62px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  position: "relative",
  overflow: "hidden",
  marginTop:(isMobile || isLaptop)?"77px":"118px",
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '-20px',
    left: 0,
    height: '100px',
    width: '100px',
    background: 'rgba(255,255,255, 0.3)',
    filter: 'blur(5px)',
    boxShadow: '0 0 10px rgba(255,255,255, 0.1)',
    transform: 'translateX(-100%)',
    animation: `${shine} 2s linear infinite`,
  },
};
    return (<>
    {!isLaptop?<Box sx={boxStyle}>
                   <TranslateText sx={{ fontSize: {xs:"16px",md:"22px"}, fontWeight: "600", lineHeight: "33px",color:"#FFF" }}>Your Wellness Awaits</TranslateText><TranslateText sx={{ fontSize: {xs:"16px",md:"20px"}, fontWeight: "500", lineHeight:{xs:"24px", md:"30px"},background:"yellow",padding:  {xs:"4px 20px",md:"8px 20px"},borderRadius:"23px",display:"inline" }}>Become a member and enjoy exclusive benefits – <a style={{color:"#27A1FF",fontWeight:600,textDecoration:"underline"}} href="https://help.mayiiq.com/search/?logicalNames=&q=member+benefits" target="_blank">learn more!</a> </TranslateText>
                </Box>:<Box sx={[boxStyle,{background:"#00C2FF",minHeight:"62px",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",gap:language?"10px":"0px"}]}>
                  <TranslateText sx={{ fontSize: {xs:language=='en'?"14px":"12px",sm:"22px"}, fontWeight: "600", lineHeight: "33px",color:"#FFF" }}> Your Wellness Awaits</TranslateText><TranslateText sx={{ fontSize: {xs:language=='en'?"14px":"12px",sm:"20px"}, fontWeight: "500", lineHeight:{xs:"24px", md:"30px"},background:"yellow",padding:  {xs:"4px 10px",md:"8px 20px"},borderRadius:"23px",display:"inline" }}><a style={{color:"#27A1FF",fontWeight:600,textDecoration:"underline"}} href="https://help.mayiiq.com/search/?logicalNames=&q=member+benefits" target="_blank">Take a Step Today!</a></TranslateText>
                </Box>

    }
        
        <Box sx={{ ...landingPage_styles.introduction.page_prop }}>
            
            <Box sx={{display:"flex",flexDirection:"column",gap:"16px",width:"100%",alignItems:"center"}}>
                
                 <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={{ ...landingPage_styles.introduction.heading1, ...landingPage_styles.fontFamily }}>INTRODUCING</TranslateText></motion.div>
                 {language=='en'?<motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Typography sx={{ ...landingPage_styles.introduction.heading2, ...landingPage_styles.fontFamily  }}>The First Innovative {isLaptop&&<br/>} Holistic Wellness Social Enterprise Movement. <span style={landingPage_styles.introduction.heading2_gradient}>Now Proudly in India</span> </Typography></motion.div>:
                 <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={{ ...landingPage_styles.introduction.heading2, ...landingPage_styles.fontFamily  }}>The First Innovative  Holistic Wellness Social Enterprise Movement.</TranslateText><TranslateText sx={landingPage_styles.introduction.heading2_gradient}>Now Proudly in India</TranslateText></motion.div>}
                 
                 <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button onClick={()=>navigate('/signup')} sx={{...landingPage_styles.introduction.button,...landingPage_styles.buttonAnimation}}><TranslateText sx={landingPage_styles.introduction.buttonText}>Signup for Free</TranslateText> <ArrowForwardIcon/></Button>
                </Box></motion.div>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    
                    <Box component="img" src={intro_image} sx={landingPage_styles.introduction.image}></Box>
                </Box>
            </Box>
        </Box></>)
}