import Header1 from "../components/header1";
import { Box, useTheme } from "@mui/material";
import Footer from "../components/footer";
import { useSelector } from "react-redux";
import AdminSidebar from "./adminSideBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const theme = createTheme({
    typography: {
        fontFamily: "Poppins",
    },
});

export const AyushChat = () => {
    const admin = useSelector((state) => state.user.role);
    const muiTheme = useTheme();

    // Corrected Responsive Breakpoints
    const isMobile = useMediaQuery(muiTheme.breakpoints.down("sm"));  // <600px
    const isTablet = useMediaQuery(muiTheme.breakpoints.between("sm", "lg"));  // 600px - 1200px
    const isDesktop = useMediaQuery(muiTheme.breakpoints.up("lg"));  // >1200px

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
                {/* Top Layout: Header and Sidebar if Admin */}
                <Box sx={{ flexShrink: 0 }}>
                    {admin === "admin" ? (
                        <Box 
                            sx={{ 
                                bgcolor: { xs: "#fff", sm: "grey.200" }, 
                                minHeight: "100vh" 
                            }}
                        >
                            <Header1 />
                            <Box 
                                sx={{ 
                                    display: "flex", 
                                    flexDirection: isMobile ? "column" : "row",
                                    mt: isMobile ? 4 : 12,
                                }}
                            >
                                <AdminSidebar />
                            </Box>
                        </Box>
                    ) : (
                        <Header1 />
                    )}
                </Box>

                {/* Middle: Chatbot responsive handling */}
                <Box 
                    sx={{ 
                        flexGrow: 1, 
                        mt: isMobile ? "10%" : isTablet ? "8%" : "6.5%", 
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center",
                        p: isMobile ? 2 : isTablet ? 3 : 4,
                        flexDirection: isMobile ? "column" : "row"
                    }}
                >
                    <iframe
                        title="Chatbot"
                        src="https://copilotstudio.preview.microsoft.com/environments/5677e7c0-a0c6-e5bc-abb7-e6fc5d85a51e/bots/cr4d9_copilot1/webchat?__version__=2"
                        style={{
                            width: isMobile ? "100%" : isTablet ? "85%" : "75%",
                            height: isMobile ? "90%" : "95%",
                            border: "none",
                            borderRadius: isMobile ? "5px" : "10px",
                            boxShadow: "8px 16px 16px hsl(0deg 0% 0% / 0.25)",
                        }}
                    />
                </Box>

                {/* Bottom: Footer */}
                <Box sx={{ flexShrink: 0 }}>
                    <Footer />
                </Box>
            </Box>
        </ThemeProvider>
    );
};
