import { Box, Button, InputBase, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import contactus from "../staticPageComponents/assets/Contact_us.png"
import { useEffect, useState } from "react";
import CustomAxios from "../utils/CustomAxios";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { landingPage_styles } from "./landing_page";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {motion} from "framer-motion"
import { TranslateText } from "../utils/googletranslator";
import { useSelector } from "react-redux";



export function ContactUs(props) {
    const language=useSelector(state=>state.user.language)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [errors, setErrors] = useState({})
    const [success, setSuccess] = useState("")
    const [isloading, setIsloading] = useState(false)
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 900px)' });

    const handleClear = () => {
        setName("")
        setEmail("")
        setSubject("")
        setMessage("")
        setErrors({})
    }


    const handleContact = () => {
        setIsloading(true)
        const formData = {
            email: email,
            name: name,
            message: message,
            subject: subject
        };
        const validate = formValidation(formData)

        if (validate) {
            CustomAxios.post("/contact_us/", formData).then(res => {
                console.log(res.data.message)
                if (res.data.message == "Successfully sent message") {
                    setSuccess("Successfully Submitted!")
                }
                setIsloading(false)
                handleClear()

            })

        } else {
            setIsloading(false)
        }
    }


    const formValidation = (formData) => {
        let valid = true;
        const newErrors = {};
        // console.log(formData)
        if (!formData.name.trim()) {
            newErrors.name = 'Enter your name.​';
            valid = false;
        }

        const emailPattern = /^\S+@\S+\.\S+$/;
        if (!formData.email.trim()) {
            newErrors.email = 'Enter your email address.​';
            valid = false;
        } else if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Please enter valid email address';
            valid = false;
        }

        if (!formData.subject.trim()) {
            newErrors.subject = 'Please enter subject';
            valid = false;
        }
        setErrors(newErrors)
        return valid

    }

    useEffect(() => {
        if (success !== "") {
            setTimeout(() => {
                setSuccess("")
            }, 30000);
        }

    }, [success])
    return (<Box id="contact" ref={props.static} sx={{...landingPage_styles.power.page_prop,...landingPage_styles.positionAnimation,bgcolor:isMobile?"grey.50":"white"}}>
        <Box sx={{ display: "flex", gap: "30px", alignItems: "flex-start", width: "100%", flexDirection:  {xs:"column" ,md: "row"} }}>
            <Box sx={{ width:{ xs:"100%",md:"50%"} }}>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: {xs:"16px",md:"30px"} }}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        {language=='en'? <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.headingBold}>Connect With Us{isMobile&&<br/>} Today</TranslateText></motion.div>: <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.headingBold}>Connect With Us Today</TranslateText></motion.div>}
                        </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.content}>We will do our best to serve you better</TranslateText></motion.div>
                        {language=='en'?<motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Typography sx={landingPage_styles.whoWeAre.content}> Email: <Typography component='a' href="mailto:support@mayiiq.com" target="blank">support@mayiiq.com</Typography> or use the form.</Typography></motion.div>:
                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{display:{lg:"flex",sm:isTablet?"flex":"block",xs:"block"},alignItems:"center",gap:"4px",justifyContent:{xs:"start",sm:"center"}}}> <Box sx={{display:"flex",gap:"4px",justifyContent:{xs:"center",md:"start"},alignItems:"center",}}><TranslateText sx={landingPage_styles.whoWeAre.content}> Email:  &nbsp; </TranslateText><TranslateText component='a' href="mailto:support@mayiiq.com" target="blank">support@mayiiq.com</TranslateText></Box><TranslateText sx={{textAlign:{xs:"center",md:"left"}}}> or use the form.</TranslateText></Box></motion.div>}
                    </Box>
                    <Box >
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.content}>No.340, B4-5, Okkiyam, Thoraipakkam, Chennai-600097, Tamilnadu, India</TranslateText></motion.div>
                        <Box >

                        <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><TranslateText sx={landingPage_styles.whoWeAre.content}>Mobile:+91 9952723647 </TranslateText></motion.div>
                        </Box>
                    </Box>
                    {/* <Button variant="contained" sx={{backgroundColor:"black",color:"white",lineHeight:"30px",width:"100%",radius:"4px",}}><Typography component="a" href="https://outlook.office365.com/owa/calendar/MAYIConsultation@mayiiq.com/bookings/" target="_blank" sx={{color:"white",textTransform:"none"}}>Online Consultation<ArrowForwardIcon/></Typography></Button> */}
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}><Box sx={{display:"flex",justifyContent:{xs:"center",md:"start"}}}><Button variant="contained" href="https://outlook.office365.com/owa/calendar/MAYIIQConsultation@arivu-iq.com/bookings/" target="_blank" sx={{...landingPage_styles.contactUs.button,...landingPage_styles.buttonAnimation,maxWidth:language=='en'?"282px":"320px",}}><TranslateText  sx={landingPage_styles.contactUs.buttonText}>Online Consultation </TranslateText><ArrowForwardIcon/></Button></Box></motion.div>

                </Box>
            </Box>
            <Box sx={{width:{ xs:"100%",md:"50%"}, display: "flex", justifyContent: "flex-start" }}>
               <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "14px" }}>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}> <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <Box sx={{display:"flex",gap:"5px"}}><TranslateText >Your name </TranslateText><span style={{ color: "red" }}>*</span></Box>
                        <InputBase value={name} onChange={(e) => setName(e.target.value)} sx={{height:isMobile?"36px":"48px",padding:"12px",border:"1px solid #CBD5E1",gap:"8px",bgcolor:"white"}}></InputBase>
                        {errors.name && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px", fontFamily: "Poppins", fontFamily: "Poppins", }}>{errors.name}</TranslateText>}
                    </Box></motion.div>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}> <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <Box sx={{display:"flex",gap:"5px"}}><TranslateText >Your email </TranslateText><span style={{ color: "red" }}>*</span></Box>
                        <InputBase value={email} onChange={(e) => setEmail(e.target.value)} sx={{height:isMobile?"36px":"48px",padding:"12px",border:"1px solid #CBD5E1",gap:"8px",bgcolor:"white"}}></InputBase>
                        {errors.email && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px", fontFamily: "Poppins", }}>{errors.email}</TranslateText>}
                    </Box></motion.div>
                   <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}>  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <Box sx={{display:"flex",gap:"5px"}}><TranslateText >Subject </TranslateText><span style={{ color: "red" }}>*</span></Box>
                        <InputBase value={subject} onChange={(e) => setSubject(e.target.value)} sx={{height:isMobile?"36px":"48px",padding:"12px",border:"1px solid #CBD5E1",gap:"8px",bgcolor:"white"}}></InputBase>
                        {errors.subject && <TranslateText sx={{ fontWeight: 400, fontSize: "14px", color: "error.light", marginBottom: "-20px", fontFamily: "Poppins", fontFamily: "Poppins", }}>{errors.subject}</TranslateText>}
                    </Box></motion.div>
                    <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}> <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <TranslateText >Your message (optional)</TranslateText>

                        <textarea value={message} onChange={(e) => setMessage(e.target.value)} style={{height:"96px",padding:"12px",border:"1px solid #CBD5E1",gap:"8px",fontFamily:"Poppins",outline:"none"}}></textarea>
                        {success && <TranslateText sx={{ color: "secondary.dark", fontSize: isMobile ? "15.6px" : "14px", fontWeight: "400", lineHeight: "18px", fontFamily: "Poppins", width: "100%" }}>{success}</TranslateText>}
                    </Box></motion.div>
                   <motion.div  viewport={{ once: true, amount: 0.8 }} initial={{ opacity: 0, y: 100 }} whileInView={{y:0.5,opacity:1}} transition={{easings:"ease-in-out",duration:.8}}>  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                        <Button variant="contained"  onClick={handleContact} sx={{maxWidth:{md: language=='en'?"155px":"200px"}, width: "100%", height: "50px", background: "linear-gradient(45deg, #00C2FF 10%, #00E3AF 90%)",color:"black",textTransform:"none",borderRadius:"4px" ,...landingPage_styles.buttonAnimation}}> {isloading ? <CircularProgress color="inherit" size={30} /> : <TranslateText sx={landingPage_styles.introduction.buttonText} >Submit</TranslateText>}<ArrowForwardIcon/></Button>
                    </Box></motion.div>
                </Box>
            </Box>

        </Box>
    </Box>)
}