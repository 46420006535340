import { Box, Typography } from "@mui/material";
import { landingPage_styles } from "./landing_page";
import panel1 from "./landing_assets/panel1.svg"
import panel2 from "./landing_assets/panel2.svg"
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import { TranslateText } from "../utils/googletranslator";
import { useSelector } from "react-redux";
export function CustomerCare() {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ maxWidth: 900  });
    const language=useSelector(state=>state.user.language)
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
    };
    return (<Box sx={{ width: "100%", height:isMobile?"100%": "449px",zIndex:2,padding:isMobile&&"24px 16px",bgcolor:isMobile?"grey.50":"white"}}>
        {!isMobile && !isLaptop?<Slider {...settings} >
            <Box sx={{ width: "100%", height: "449px", background: "linear-gradient(45deg, #6864F7 10%, #FF405C 90%)", padding: "64px 120px" }}>
                <Box sx={{ display: "flex", gap: "40px", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ width: "60%", display: "flex", flexDirection: "column", display: "flex", gap: "28px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", display: "flex", gap: "8px" }} >
                            <TranslateText sx={{ ...landingPage_styles.whoWeAre.heading, color: "white" }}>Need Assistance?</TranslateText>
                            <TranslateText sx={{ ...landingPage_styles.whoWeAre.headingBold, color: "white" }}>CUSTOMER SUPPORT</TranslateText>
                        </Box>
                        <TranslateText sx={{ ...landingPage_styles.whoWeAre.content, color: "white" }}>Our customer service team is here to help you with any questions or concerns you may have.</TranslateText>
                    </Box>
                    <Box >
                        <Box component="img" sx={{ width: "100%", borderRadius: "24px", border: "4px solid white",maxWidth:"585px",width:"100%",height:"321px",objectFit:"cover"}} src={panel1}></Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ width: "100%", height: "449px", background: "linear-gradient(45deg, #00C2FF 10%, #00E3AF 90%)", padding: "64px 120px" }}>
                <Box sx={{ display: "flex", gap: "40px", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{  width: "60%", display: "flex", flexDirection: "column", display: "flex", gap: "28px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", display: "flex", gap: "8px" }} >
                            {language=='en'?<TranslateText sx={{ ...landingPage_styles.whoWeAre.heading, color: "white" }}>Safe & Fast</TranslateText>:<TranslateText sx={{ ...landingPage_styles.whoWeAre.heading, color: "white" }}>Safe and Fast</TranslateText>}
                            
                            <TranslateText sx={{ ...landingPage_styles.whoWeAre.headingBold, color: "white" }}>SECURED PAYMENT</TranslateText>
                        </Box>
                        <TranslateText sx={{ ...landingPage_styles.whoWeAre.content, color: "white" }}>Our platform employs the latest encryption technology to safeguard your financial information and provide a seamless payment experience.</TranslateText>
                    </Box>
                    <Box >
                        <Box component="img" sx={{width: "100%", borderRadius: "24px", border: "4px solid white",maxWidth:"585px",width:"100%",height:"321px",objectFit:"cover"}} src={panel2}></Box>
                    </Box>
                </Box>
            </Box>
        </Slider>:
        <Box sx={{width:"100%",height:"100%",display:"flex",gap:"16px",overflow:"auto","&::-webkit-scrollbar": {display: "none"},zIndex:2}}>
            <Box sx={{ width: "100%", height: {xs:language=='en'? "378px":"500px",sm:"100%"}, background: "linear-gradient(45deg, #6864F7 10%, #FF405C 90%)",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                <Box sx={{ display: "flex", height: language=='en'? "236px":"340px", gap: "40px", justifyContent: "center", alignItems: "center",width:{xs:"283px",sm:"100%"} , padding: "24px 16px",flexDirection:"column",alignItems:"center",justifyContent:"flex-start" }}>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", display: "flex", gap: "8px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", display: "flex", gap: "8px"}} >
                            <TranslateText sx={{ fontSize:"20px",fontWeight:"600",textAlign:"left", color: "white" }}>Need Assistance?</TranslateText>
                            <TranslateText sx={{ fontSize:"24px",fontWeight:"600",textAlign:"left", color: "white",width:"100%" }}>CUSTOMER SUPPORT</TranslateText>
                        </Box>
                        <TranslateText sx={{ fontSize:"16px",fontWeight:"400",textAlign:"left", color: "white",lineHeight:"24px" }}>Our customer service team is here to help you with any questions or concerns you may have.</TranslateText>
                    </Box>
                </Box>
                        <Box component="img" sx={{ width: "100%", borderRadius: "0px", border: "none" }} src={panel1}></Box>
            </Box>
            <Box sx={{ width: "100%", height: {xs:language=='en'? "378px":"500px",sm:"100%"}, background: "linear-gradient(45deg, #00C2FF 10%, #00E3AF 90%)",display:"flex",flexDirection:"column",justifyContent:"space-between" }}>
                <Box sx={{ display: "flex", height: language=='en'? "236px":"340px", gap: "40px", justifyContent: "center", alignItems: "center",width:{xs:"283px",sm:"100%"} , padding: "24px 16px",flexDirection:"column",alignItems:"center",justifyContent:"flex-start" }}>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", display: "flex", gap: "8px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", display: "flex", gap: "8px"}} >
                            <TranslateText sx={{ fontSize:"20px",fontWeight:"600",textAlign:"left", color: "white" }}>Safe & Fast</TranslateText>
                            <TranslateText sx={{ fontSize:"24px",fontWeight:"600",textAlign:"left", color: "white",width:"100%" }}>SECURED PAYMENT</TranslateText>
                        </Box>
                        <TranslateText sx={{ fontSize:"16px",fontWeight:"400",textAlign:"left", color: "white",lineHeight:"24px"  }}>Our platform employs the latest encryption technology to safeguard your financial information and provide a seamless payment experience.</TranslateText>
                    </Box>
                </Box>
                        <Box component="img" sx={{ width: "100%", borderRadius: "0px", border: "none" }} src={panel2}></Box>
            </Box>
        </Box>}

    </Box>)
}