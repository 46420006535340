

import Header1 from "../components/header1";
import { Box, Typography , keyframes} from "@mui/material"
import banner from "../assets/hero.jpg"
import Layout from "../layout"
import Cards from "../components/cards_old"
import { useState } from "react"
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { useDispatch, useSelector } from "react-redux"
import { updateSideBar, updateCartSideBar } from "../redux/active_bar"
import AdminSidebar from "../components/adminSideBar"
import { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ProductList from "../components/productList"
import Product_list from "../components/product_list"
import Footer from "../components/footer"
import MessageBar from "./messageBar"
import CartSideMount from "../components/cartSideMount"
import MeetOurExperts from "../landing_page/meetourexperts"

import { landingPage_styles } from "../landing_page/landing_page";
const Home = () => {
    const dispatch = useDispatch()
    const [total,setTotal]=useState(0.00)
    const cartSidebar=useSelector(state=>state.sideBar.cartSidebar)
    // const [admin, setAdmin] = useState(false)
    console.log(cartSidebar)
    const admin=useSelector(state=>state.user.role) 
    const cart=useSelector(state=>state.product.cart)
    const authtoken=localStorage.getItem("authtoken")
    const member=useSelector(state=>state.user.member)
    useEffect(() => {
        const calculateTotal = () => {
            let totalPrice = 0;
            cart.forEach(item => {
                const price = authtoken&&member=='member' ? parseFloat(item.product?.selling_price) + Math.round(parseFloat(item.product?.selling_price) * (parseFloat(item.product?.tax)/100) ) : parseFloat(item.product.price) + Math.round( parseFloat(item.product.price) * parseFloat(item.product.tax/100) );
                totalPrice += parseInt(price) * item.quantity;
            });
            setTotal(parseFloat(totalPrice).toFixed(2));
        };
        calculateTotal();
    }, [cart, authtoken]);
    const isLaptop = useMediaQuery({ query: '(max-width: 900px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const shine = keyframes`
    0% {
      transform: translateX(0px) rotate(25deg);
    }
    100% {
      transform: translateX(2000px) rotate(25deg);
    }
  `;
    const boxStyle = {
        background: "#00C2FF",
        height: "62px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        position: "relative",
        overflow: "hidden",
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '-20px',
          left: 0,
          height: '100px',
          width: '100px',
          background: 'rgba(255,255,255, 0.3)',
          filter: 'blur(5px)',
          boxShadow: '0 0 10px rgba(255,255,255, 0.1)',
          transform: 'translateX(-100%)',
          animation: `${shine} 2s linear infinite`,
        },
      };

     
   
    return (
        <Box sx={{overflowY:cartSidebar?"hidden":"auto",height:"100vh"}}>
            {admin=="admin" ? <Box sx={{ bgcolor: { sm: "grey.200", xs: "#fff" }, minHeight: "calc(100vh - 120px)" ,marginBottom:"54px"}}>
                <Header1 />
                <Box sx={{ display: "flex", marginTop: "120px" }}>
                    <AdminSidebar />

                </Box>
            </Box>

                : <Box sx={{marginBottom:{xs:"60px",md:"0px",},}}><Layout />
                    <Box sx={{bgcolor:"grey.100",marginTop:{xs:"77px",md:"118px"}}}>
                        {!isMobile?<Box sx={boxStyle}>
                    <Typography sx={{...landingPage_styles.introduction.discountText,color:"#FFF",fontWeight:600}}>Join now and unlock 25% off on all products </Typography>
                    <Typography sx={landingPage_styles.introduction.discount}>plus exclusive <a style={{color:"#27A1FF",fontWeight:600,textDecoration:"underline"}} href="https://help.mayiiq.com/search/?logicalNames=&q=member+benefits" target="_blank"> member benefits!</a></Typography>
                    {/* <Typography sx={landingPage_styles.introduction.discountText}> on all purchases.</Typography> */}
                </Box>:<Box sx={[boxStyle,{background:"#00C2FF",height:"62px",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",gap:"10px"}]}>
                <Typography sx={{...landingPage_styles.introduction.discountText,color:"#FFF",fontWeight:600}}>Join now for</Typography>
                <Typography sx={landingPage_styles.introduction.discount}>25% off</Typography>
                </Box>}
        <MessageBar/>
                        <Box sx={{ position: "relative", width: "100%", marginTop: { sm: "10x", xs: "0px" } }}>
                            <Box component='img' src={banner} sx={{ width: "100%" }} />
                            <Box sx={{ position: "absolute", top: "50%", left: "10%", transform: 'translateY(-50%)',maxWidth:"30%",width:"100%" }}>
                                <Typography sx={{ textAlign: "left", fontWeight: "700", fontSize: { xs: "14px", sm: "24px", lg: "53px" }, color: "white",lineHeight:{lg:"63px"}}}>
                                    DISCOVER THE POWER OF AYURVEDA
                                </Typography>
                                <Typography sx={{ textAlign: "left", fontWeight: "500", fontSize: { xs: "10px", sm: "20px", lg: "41px" }, color: "white",lineHeight:{lg:"61.5px"} }}>
                                    Shop online for natural healing & wellness!
                                </Typography>
                            </Box>

                        </Box>
                        
                        
                        {/* <Cards /> */}
                        <Product_list/>
                        

                    </Box>
                   {/* <CartSideMount/> */}
                   
                  
                </Box>}
                {/* <MeetOurExperts/>  */}

            <Footer/>

        </Box>
    )

}
export default Home  