import { Box, Button, Checkbox, InputBase, Stack, Typography } from "@mui/material";
import Notification_png from "../../assets/admin/Notification.png"
import AdminSidebar from "../../components/adminSideBar"
import Header1 from "../../components/header1"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Mobile_EmailVerify from "../otp/mobile_emailVerify";
import VerifiedIcon from '@mui/icons-material/Verified';
import CustomAxios from "../../utils/CustomAxios";
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../../redux/snackbar";
import MessageBar from "../messageBar";
import { updateAdminReducer } from "../../redux/admin";
import { updateMembership, updateMobile } from "../../redux/user_reducer";




const NotificationSettings = ()=>{
    const dispatch = useDispatch()
    const admin_mobileNo = useSelector(state=>state.user.mobile_no)
    const [mobileno,setMobileno] = useState("")
    const user_response = useSelector(state=>state.user)
    const [errors,setErrors] = useState({})
    const [openOtpModal,setOpenOtpModal] = useState(false)
    const isMobileVerified = useSelector(state=>state.user.isVerifyTick)
    
    const handleMobileChange = (e)=>{
        if(mobileno?.length<10){
            setMobileno(e.target.value.replace(/\D/g, ""))
        }
        else{
            setMobileno(e.target.value.replace(/\D/g, "").slice(0,10))
        }
    }
    const handleVerifyOtp = (e)=>{
        e.preventDefault()
        if(mobileno && mobileno != admin_mobileNo && mobileno?.length === 10){
            setErrors({})
            setOpenOtpModal(true)
        }
        else if(mobileno && admin_mobileNo && mobileno === admin_mobileNo){
            setErrors({})
            dispatch(updateSnackBarMessage("No changes detected."))
            dispatch(updateSnackBarSeverity('error'))
            dispatch(updateSnackBarOpen(true))

        }
        else{
            setErrors({"mobileno":"Enter a valid number."})
        }
        
    }


    useEffect(()=>{
        dispatch(updateAdminReducer({type:"isSettingsPanelExpanded",value:true}))
        CustomAxios.post(`get_details/`, {"user_id": user_response.id,"admin":true}).then((res)=>{
            dispatch(updateMobile(res.data.user.mobileno))
            dispatch(updateMembership(res.data.user.member))
        })
    },[])

    useEffect(()=>{
        setMobileno(admin_mobileNo)
    },[admin_mobileNo])


    return(
        <Stack direction="row" sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh)" }}>
            <Header1/>
            <Stack direction="row" sx={{ display: "flex", paddingTop: "144px",gap:"24px",mx:"24px" , position:"relative",width:"100%"}}>
                <AdminSidebar/>
                <Stack sx={{p:"24px",gap:"24px",bgcolor:"#FFFFFF",width:"100%"}}>
                    <MessageBar/>
                    <Stack>
                        <Stack direction={'row'} sx={{gap:"10px",alignItems:"center"}}>
                            <Box component={'img'} src={Notification_png} sx={{width:"32px",height:"32px"}}/>
                            <Typography sx={{fontWeight:700,fontSize:"16px",lineHeight:"24px",color:"#000000"}}>Notification Settings</Typography>
                        </Stack>
                    </Stack>
                    <Stack sx={{gap:"16px"}}>
                        <Typography sx={{fontWeight:700,fontSize:"16px",lineHeight:"24px",color:"#000000"}}>WhatsApp Notification Settings</Typography>
                        <Stack sx={{maxWidth:"390px",gap:"8px"}}>
                            <Typography sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>Enter Mobile number</Typography>
                            <InputBase value={mobileno} sx={{p:"8px 12px",border:"1px solid #CBD5E1",borderRadius:"4px",height:"36px"}} placeholder="eg:1234567890" onChange={(e)=>{handleMobileChange(e)}}
                            startAdornment={<Typography sx={{pr:"8px"}}>+91</Typography>} 
                            endAdornment={(mobileno && admin_mobileNo && mobileno == admin_mobileNo) ? <VerifiedIcon sx={{color:"secondary.light"}} /> : null}
                            />
                            {errors.mobileno && <Typography sx={{fontWeight: 400,fontSize: "14px",color: "error.light",}}>{errors.mobileno}</Typography>}
                        </Stack>
                    </Stack>
                    <Stack sx={{gap:"16px"}}>
                        <Typography sx={{fontWeight:700,fontSize:"16px",lineHeight:"24px",color:"#000000"}}>Notify me when:</Typography>
                        <Stack sx={{gap:"12px"}}>
                            <Stack direction={"row"} sx={{gap:"8px",alignItems:"center"}}>
                                <Checkbox disabled checked sx={{width:"16px",height:"16px",borderRadius:0,color:"#D1D5DB"}}/>
                                <Typography sx={{fontWeight:400,fontSize:"16px",lineHeight:"24px",color:"#1E293B"}}>New orders are received</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Button variant="contained" sx={{bgcolor:"#1099F4",color:"#FFFFFF",p:"8px 12px",borderRadius:"4px",height:"36px",width:"max-content",fontSize:"14px",lineHeight:"20px"}} onClick={(e)=>handleVerifyOtp(e)}>Save Notification Settings</Button>
                </Stack>
            </Stack>
            <Mobile_EmailVerify verify="mobile" whatsapp={true} open={openOtpModal} mobile={mobileno} />
        </Stack>
    )
}


export default NotificationSettings