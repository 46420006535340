

import { Box } from "@mui/material"
import Header from "./components/header"
import BottomBar from "./components/bottombar"
import { useMediaQuery } from "react-responsive"
import Sidebar from "./components/sidebar"
import Address from "./components/address_modal"
import AdminSidebar from "./components/adminSideBar"
import Cart from "./components/cart"
import { useEffect } from "react"

import { jwtDecode } from 'jwt-decode';
import { useDispatch } from "react-redux"
import { updateFirstName, updateLastName, updateEmail, updateRole, updateId, updateAllAddress,updateMemberResponse, updateUserName, updateMobile,updateWhatsapp, updateAccumulated, updateGetBank, updateWithdrawRequest, updateMembership } from "./redux/user_reducer"
import { updateCart } from "./redux/products"
import axios from "axios"
import CustomAxios from "./utils/CustomAxios"
import { updateProfile } from "./redux/user_reducer"
import CartSideMount from "./components/cartSideMount"
import Header1 from "./components/header1"
import { TransactionPinVerification } from "./modules/transactionPIN_verify"
import BankForm from "./modules/bankForm"
const Layout = () => {
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });

    const authtoken = localStorage.getItem("authtoken")
    const add=localStorage.getItem('address')
    // // console.log(authtoken)
    const dispatch = useDispatch()

    useEffect(() => {
        if (authtoken) {
            
            const decode = jwtDecode(authtoken)
            dispatch(updateFirstName(decode.first_name))
            dispatch(updateLastName(decode.last_name))
            dispatch(updateEmail(decode.email))
            console.log("decoding user role")
            dispatch(updateRole(decode.role))
            dispatch(updateId(decode.id))
            // // console.log(decode)
            handleCart()
            fetchuserdetails(decode.id)
        }
        // // console.log(add)
        if(add){
            dispatch(updateAllAddress(JSON.parse(add)))
        }
        if (localStorage.getItem("cart")&&!authtoken) {
            // // console.log(localStorage.getItem("cart"))
            const cart = localStorage.getItem("cart")
            // // console.log(cart)
            dispatch(updateCart(JSON.parse(localStorage.getItem("cart"))))
        }
        else {
            // // console.log("added")
            localStorage.setItem("cart", [])
        }
        

    }, [authtoken,add])

    
          const fetchuserdetails = async (userid) => {
            // // console.log("id", id)
            const payload = {
                user_id: userid
            }
            await CustomAxios.post(`get_details/`, payload).then(response => {
                console.log(response.data)
                dispatch(updateFirstName(response.data.user.first_name))
                dispatch(updateLastName(response.data.user.last_name))
                dispatch(updateEmail(response.data.user.email))
                dispatch(updateUserName(response.data.user.username))
                dispatch(updateMobile(response.data.user.mobileno))
                // dispatch(updateRole(decode.role))
                dispatch(updateWhatsapp(response.data.user.whatsapp))
                dispatch(updateId(response.data.user.id))
                dispatch(updateMemberResponse(response.data))
                dispatch(updateProfile(response.data.att?.original))
                dispatch(updateAccumulated(response.data.accumulated))
                dispatch(updateGetBank(response.data.bankdetails))
                dispatch(updateWithdrawRequest(response.data.withdrawRequest))
                dispatch(updateMembership(response.data.user.member))
                
            }
            ).catch(error => {
                // console.log(error)
            })
        }
    const handleCart=async()=>{
        const decode = jwtDecode(authtoken)
        const response = await CustomAxios.get(`usercart/${decode.id}`,)
        // const user=await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}user/${decode.id}`)
        // // console.log(user)
            // // console.log(response)
            dispatch(updateCart(response.data))
            localStorage.setItem("cart", JSON.stringify(response.data))
    }
    
    

    return <Box sx={{ bgcolor: { sm: "grey.200", xs: "#fff", } }}>
        <Header1 />
        {!isLaptop&&authtoken && <BottomBar />}
        <Sidebar />
        <Address />
        <BankForm/>
        <TransactionPinVerification/>
        <CartSideMount/>
        <Cart />

    </Box>


}
export default Layout