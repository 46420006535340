import { Box,Paper,Button,Typography, TextField } from "@mui/material"
import { styles } from "./cards_old"
import { Link } from "react-router-dom"
import ProductList from "./productList"
import OrderList from "./orderList"
import { useState } from "react"
const OrderComponent=()=>{
    const [texttoFilter, setTexttoFilter] = useState("")

return (
    <Box  sx={{  width: {xs:"calc(100% )",sm:"100%"} }} >

                <Paper sx={{ width: "100%", bgcolor: "#fff", padding: "16px", display:"flex",flexDirection:"column",height:"80vh",overflow:"auto"  }}>
                    <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <Typography sx={{fontSize: "20px", lineHeight: "28px", fontWeight: "700" }}>Order Management</Typography>
                   
                    </Box>
                   
                    {/* <Button style={styles.Button} variant="contained" component={Link} to="/product/1" >+ Add Products</Button> */}
                
                <OrderList texttoFilter={texttoFilter}/>
                </Paper>

            </Box>
)
}

export default OrderComponent