
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../App';
import SideBarList from './list';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CustomAxios from '../utils/CustomAxios';
import logo from "../assets/mayi_dark.png"
import logoDark from "../assets/Mayi_logo.png"
import { Button, Paper,Menu, Typography, Stack, Alert, List, ListItemButton, ListItemText, ListItem } from '@mui/material';
import { inputStyles } from '../forms/signup_form';
import { useMediaQuery } from 'react-responsive';
import { useLocation, Link, Navigate, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import { Info } from '@mui/icons-material';
import { styled } from '@mui/system';
import { jwtDecode } from 'jwt-decode';
// import { useLocation } from 'react-router-dom';

// const BlinkingButton = styled(Button)`
//   @keyframes blink {
//     0% { opacity: 1; }
//     50% { opacity: 0; }
//     100% { opacity: 1; }
//   }

//   animation: blink 1s infinite;
// `;
export default function Header({staticWeb}) {
    const theme = useTheme();
    const dispatch=useDispatch
    const[login,setLogin]= useState(false)
    const colorMode = React.useContext(ColorModeContext);
    const id=useSelector(state=>state.user.id)
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation()
    let profile = useSelector(state => state.user.profile)
    let res = useSelector(state => state.user.member_response)
    // const location=useLocation()
    // console.log(res)
    const authtoken=localStorage.getItem('authtoken');
    const [imagestatus,setImageStatus]=useState(false)
    const navigate = useNavigate()
    // // console.log(authtoken)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElMobile, setAnchorElMobile] = React.useState(null);
  const open = Boolean(anchorEl);
  const openMobile = Boolean(anchorElMobile);
  const handleClick = (event,location) => {
    console.log(location)
    if(location === "profile"){
      setAnchorEl(event.currentTarget);
    }
    if(location === "menu"){
      setAnchorElMobile(event.currentTarget);
    }
  };
  const handleClose = (event,location) => {
    if(location === "profile"){
    setAnchorEl(null);
    }
    if(location === "menu"){
      setAnchorElMobile(null);
    }
  };
  useEffect(()=>{
    if(profile!=""){
      setImageStatus(true)
    }
  },[profile])
    // // console.log(location)
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const isLaptop = useMediaQuery({ maxWidth: 900});
    const updateProfile=()=>{

    }
    let  first_name = useSelector(state => state.user.first_name)
    let  last_name = useSelector(state => state.user.last_name)
   
    // // console.log(profile)
    const capitalize = (str) => {
      return str?.split(' ').map(word => word?.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };
    function stringToColor(string) {
      let hash = 0;
      let i;
    
      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    
      let color = '#';
    
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }
      /* eslint-enable no-bitwise */
    
      return color;
    }
  function stringAvatar(name) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }
    // const scrollToSection = (id, e) => {
    //   e.preventDefault();
    //   // const element = document.getElementById(id);
    //   // if (element) {
    //     window.location.href=`/static/#${id}`
    //     // element.scrollIntoView({ behavior: 'smooth' });
    //   // }
    // };
// // console.log(res)
    return (
        // <Box sx={{display:"flex",alignItems: "start", justifyContent: "space-between", width:"100%"}}>


        <Box
            sx={{
                position: 'fixed',
                height:"84px",
                top: 0,
                left: 0,
                width: "calc(100% )",
                backgroundColor: staticWeb?!isScrolled?"#eff1f3":"#fff":!isScrolled || location.pathname == '/profile' || location.pathname == "/orderstatus" ? "#fff" : "#fff",
                backdropFilter: staticWeb?'blur(10px)':"none", // Apply blur effect
                WebkitBackdropFilter: staticWeb?'blur(10px)':"none",
                borderBottom: "1px solid",borderColor:"grey.300",
                transition: 'background-color 0.3s ease',
                color: "white",
                padding: "10px 24px",
                zIndex: "20",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

            }}
            
        >

        <Box sx={{display:"flex",gap:"20px"}}>
            <Box component="img" sx={{ width: "100px", cursor:"pointer" }} onClick={()=> navigate("/")}  src={logoDark} />
           {!isMobile && !isLaptop&& <Box sx={{ display: "flex", gap: "24px", marginLeft: "16px",alignItems:"flex-end" }}>
                    {/* <a  href= {location.pathname === "/"? "#info":"/?scrollto=info"}  style={{textDecoration:"none"}}>
                        <Typography variant="text" color="inherit" sx={{fontFamily:"poppins", color: "#444444",fontWeight: "600",padding:"10px",transform:"scaleX(0.5)", "&:hover": { fontWeight: "600", borderBottom:"3px solid #27a2fd",color:"#27a2fd",padding:"10px",transform:"scaleX(1)",transition:"border-bottom 0.3s cubic-bezier(0.55, 0, 0.1, 1)"} }} >Info</Typography>
                    </a> */}
                    <a  href= {location.pathname === "/"? "#who-we-are":"/?scrollto=who-we-are"} style={{textDecoration:"none"}}>
                        <Typography variant="text" color="inherit" sx={{fontFamily:"poppins", color: "#444444",fontWeight: "600",padding:"10px",transform:"scaleX(0.5)", "&:hover": { fontWeight: "600", borderBottom:"3px solid #27a2fd",color:"#27a2fd",padding:"10px",transform:"scaleX(1)",transition:"border-bottom 0.3s cubic-bezier(0.55, 0, 0.1, 1)"} }}>Who We Are</Typography>
                    </a>
                    <a  href= {location.pathname === "/"? "#ourculture":"/?scrollto=our_Culture"} style={{textDecoration:"none"}}>
                        <Typography variant="text" color="inherit" sx={{fontFamily:"poppins", color: "#444444",fontWeight: "600",padding:"10px",transform:"scaleX(0.5)", "&:hover": { fontWeight: "600", borderBottom:"3px solid #27a2fd",color:"#27a2fd",padding:"10px",transform:"scaleX(1)",transition:"border-bottom 0.3s cubic-bezier(0.55, 0, 0.1, 1)"} }}>Our Culture</Typography>
                    </a>
                    <Link to="/products" style={{ textDecoration: "none" }}>
                        <Typography variant="text" color="inherit" sx={{ fontFamily:"poppins",color: "#444444",fontWeight: "600",padding:"10px",transform:"scaleX(0.5)", "&:hover": { fontWeight: "600", borderBottom:"3px solid #27a2fd",color:"#27a2fd",padding:"10px",transform:"scaleX(1)",transition:"border-bottom 0.3s cubic-bezier(0.55, 0, 0.1, 1)"} }} >Our Products</Typography>
                    </Link>
                    <a   href= {location.pathname === "/"? "#contact":"/?scrollto=contactus"}   style={{textDecoration:"none"}}>
                        <Typography variant="text" color="inherit" sx={{fontFamily:"poppins", color: "#444444",fontWeight: "600",padding:"10px",transform:"scaleX(0.5)", "&:hover": { fontWeight: "600", borderBottom:"3px solid #27a2fd",color:"#27a2fd",padding:"10px",transform:"scaleX(1)",transition:"border-bottom 0.3s cubic-bezier(0.55, 0, 0.1, 1)"} }} >Contact</Typography>
                    </a>
              </Box>}
              </Box>
              <Box sx={{display:"flex",gap:"20px"}}>
              {/* {(isMobile || isLaptop)&& authtoken && res && res.account?.role=="user" && !res?.account?.kyc_status && <BlinkingButton component="a" href="/profile/?scrollto=kyc" variant="contained" color="error" sx={{borderRadius:"50px",fontWeight:"600"}}>
                KYC
              </BlinkingButton>} */}
              <Stack direction="row" sx={{alignItems:"center",gap:"12px",justifyContent:"flex-end"}}>
              {authtoken && res && res.account?.role=="user" && !res?.account?.kyc_status && 
              <Stack direction="row" sx={[{color:"#DC362E", alignItems:"center",gap:"4px",justifyContent:"flex-end"},isMobile&&{pl:"20px"}]}>
                <Info sx={{width:"16px",height:"16px"}}/>
                <Typography sx={{fontWeight:400,fontSize:"12px",lineHeight:"16px",textAlign:"right"}}>{isMobile?"":"Complete your KYC for a smooth experience!"} <Box component="a" href="/profile/?scrollto=kyc" sx={{cursor:"pointer",textDecoration:"underline",color:"inherit","&:hover":{color:"inherit"}}}>{isMobile?"Complete your KYC":"Verify Now!"}</Box></Typography>
              </Stack>}
              {!isMobile&&!isLaptop&&authtoken? <>{profile&&imagestatus ? <img src={profile} style = {{width:"40px",height:"40px",objectFit:"cover",background:"#CBD5E1",border:"none", borderRadius:"36px",cursor:"pointer"}} alt='Profile'  onError={(e) => {setImageStatus(false)}} onClick={(e)=>handleClick(e,'profile')} />:<Avatar {...stringAvatar(capitalize(first_name)+" "+capitalize(last_name))} onClick={(e)=>handleClick(e,'profile')} style={{cursor:"pointer",border:authtoken && res && res.account?.role=="user" && !res?.account?.kyc_status ? "2px solid #DC362E":"unset"}} />} </>:!authtoken && <Box sx={{display:"flex",gap:"30px"}}><Button sx={inputStyles.button} component={Link} to="/login" variant="outlined">Sign in / Sign up</Button></Box>}
            </Stack>
            
            {(isMobile || isLaptop) &&<Button variant='contained' sx={{maxWidth:"34px",height:"34px",bgcolor:"primary.light",padding:"0px",minWidth:"34px"}} onClick={(e)=>handleClick(e,'menu')}><MenuIcon sx={{color:"#FFF",width:"14px",height:"14px"}}/></Button>}


            <Menu
               anchorEl={(isMobile|| isLaptop)?anchorElMobile:anchorEl}
               id="account-menu"
               open={(isMobile|| isLaptop)?openMobile:open}
               onClose={(e)=>{(isMobile|| isLaptop)?handleClose(e,'menu'):handleClose(e,'profile')}}
               onClick={(e)=>{(isMobile|| isLaptop)?handleClose(e,'menu'):handleClose(e,'profile')}}
               PaperProps={{
                 elevation: 0,
                 sx: {
                   overflow: 'visible',
                   filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                   mt: 1.5,
                   '& .MuiAvatar-root': {
                     width: 32,
                     height: 32,
                     ml: -0.5,
                     mr: 1,
                   },
                   '&::before': {
                     content: '""',
                     display: 'block',
                     position: 'absolute',
                     top: 0,
                     right: 14,
                     width: 10,
                     height: 10,
                     bgcolor: 'background.paper',
                     transform: 'translateY(-50%) rotate(45deg)',
                     zIndex: 0,
                   },
                 },
               }}
               transformOrigin={{ horizontal: 'right', vertical: 'top' }}
               anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {!isMobile && !isLaptop&&<SideBarList/>}
                {(isMobile|| isLaptop)&&
          <List>
           
            {/* <ListItem  disablePadding  >
                <ListItemButton component="a" href= {location.pathname === "/"? "#info":"/?scrollto=info"} sx={{height:"30px"}} >
                    
                    <ListItemText primary={"Info"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem> */}

            <ListItem  disablePadding  >
                <ListItemButton component="a" href= {location.pathname === "/"? "#who-we-are":"/?scrollto=who-we-are"}  sx={{height:"30px"}} >
                   
                    <ListItemText primary={"Who We Are"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem  disablePadding  >
                <ListItemButton component="a" href= {location.pathname === "/"? "#ourculture":"/?scrollto=our_Culture"}  sx={{height:"30px"}} >
                   
                    <ListItemText primary={"Our Culture"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem  disablePadding >
                <ListItemButton component={Link} to="/products" sx={{height:"30px"}}  >
               
                    <ListItemText primary={"Our Products"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem  disablePadding >
                <ListItemButton component="a" href= {location.pathname === "/"? "#contact":"/?scrollto=contactus"} sx={{height:"30px"}}  >
               
                    <ListItemText primary={"Contact"} sx={{color:"black.600",fontWeight:600,padding:"10px",fontSize:"16px"}}/>
                </ListItemButton>
            </ListItem></List>}

            </Menu>
            </Box>


        </Box>

        // </Box>
    )
}