
import { Card, CardContent, Typography, CardMedia, CardActions, Button, Box, Grid } from "@mui/material/"
import prodOne from "../assets/prodOne.png"
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useEffect, useState } from "react";
import axios from "axios";
import CustomAxios from '../utils/CustomAxios';
import { useDispatch, useSelector } from "react-redux";
import { updateAllProduct, updateCart } from "../redux/products";
import { useMediaQuery } from "react-responsive";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { json, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ayurvedic from "../assets/ayurvedic.svg"
import organic from "../assets/organic.svg"
import { updateSnackBarMessage,updateSnackBarSeverity,updateSnackBarOpen } from "../redux/snackbar";
export const styles = {

    card: {},
    header: { fontSize: "16px", fontWeight: 600, },
    unit: { color: "grey.500", fontSize: "14px", },
    price: { color: "grey.500", fontSize: "14px",textDecoration:"line-through" },
    sellingPrice: { fontSize: "16px", fontWeight: 600 },
    Button: {
        textTransform:"none",
        border: "0.5px solid rgba(105, 189, 255, 1)", borderTopLeftRadius: 20,
        height:"40px",
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20, "&:hover": { bgcolor: "rgba(105, 189, 255, 1)", color: "#fff" }, display: "flex", alignItems: "center", gap: "8px", padding: "8px", fontSize: "14px",fontWeight:"600" ,width:"90px"
    }
}



const Cards = (params) => {
    const navigate = useNavigate()
    const cart = useSelector(state => state.product?.cart)
    const id = useSelector(state => state.user.id)
    const authtoken = localStorage.getItem("authtoken")
    const dispatch = useDispatch()
    useEffect(() => {
        // // console.log(product)
        getProducts()
    }, [])
    // // console.log(params.prod)
    useEffect(() => {


    }, [cart])
    const getProducts = async () => {

        await axios(`${process.env.REACT_APP_BACKEND_BASE_URL}products/`,).then(({ data }) => {
            // console.log(data)
            setProduct(data)

        })
    }
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const [product, setProduct] = useState()
    // // console.log(product)
    const prod = useSelector(state => state.product.Allproducts)
    useEffect(() => {
        setProduct(prod)
    }, [prod])

    const updateQuantity = async (object, condition) => {
        // console.log(object)
        let products = [...cart]
        const newCart = products.map(item => {
            if (condition && item.product?.id == object.id) {
                let updated = { ...item }
                updated.quantity += 1
                // // console.log("if condition")
                if (item.id) {
                    CustomAxios.patch(`cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice:updated.quantity*(parseFloat(updated.price)+ Math.round(updated?.price * (updated.product?.tax/100) ) )
                    })
                    return updated
                }
                else {

                    return updated

                }

            }
            else if (!condition && item.product?.id == object.id) {

                let updated = { ...item }
                updated.quantity -= 1

                if (item.id && updated.quantity >= 1) {
                    CustomAxios.patch(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`, {
                        quantity: updated.quantity,
                        totalprice:updated.quantity*(parseFloat(updated.price)+ Math.round(updated?.price * (updated.product?.tax/100) ) )
                    })
                    return updated
                }
                else if (item.id && updated.quantity < 1) {
                    CustomAxios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}cart/${item.id}/`)
                    return updated
                }
                else {

                    return updated

                }


            }
            else {
                return item

            }

        })

        
        // setProduct(products)
        dispatch(updateCart(newCart.filter((item) => !item.quantity < 1)))
        
            // console.log("clipboard open")
            dispatch(updateSnackBarMessage("Successfully Updated the Cart!"))
            dispatch(updateSnackBarSeverity("success"))
          
          dispatch(updateSnackBarOpen(true))
        localStorage.setItem("cart", JSON.stringify(newCart.filter((item) => !item.quantity < 1)))

    }
    const addCart = (item, quantity) => {
        const addCart = { product: item, quantity: quantity }
        dispatch(updateCart([...cart, addCart]))
        localStorage.setItem("cart", JSON.stringify([...cart, addCart]))
        if (authtoken) {
            handleCart(item, quantity).then(() => {
                // console.log("clipboard open")
                dispatch(updateSnackBarMessage("You are exceeding the available stock!.You can add only 11 quantity to your cart."))
                dispatch(updateSnackBarSeverity("success"))
              })
              .catch((error) => {
                dispatch(updateSnackBarMessage())
                dispatch(updateSnackBarSeverity("error"))
              });
              dispatch(updateSnackBarOpen(true))
        }
        
    }
    const handleCart = async (item, quantity) => {
        // console.log(item, quantity, id)

        await CustomAxios.post(`cart/`, {
            products: item.id,
            quantity: quantity,
            user: id, created_by: id,
            price:item.selling_price,
            totalprice:quantity*(parseFloat(item.selling_price)+ Math.round(item.selling_price * (item.tax/100) ) ),
            updated_by: id
        })
        const response = await CustomAxios.get(`usercart/${id}`,)
        // console.log(response)
        dispatch(updateCart(response.data))
        localStorage.setItem("cart", JSON.stringify(response.data))


    }
    const cartCheck = (id) => {
        // // console.log(id)
        // // console.log(cart)
        const filt = cart.filter(item => item.product?.id == id)
        // filt[0].quantity
        if (filt.length > 0) {
            return filt[0].quantity

        }
        else {
            return false
        }

    }
    const addCartAdding = (item) => {
        let addon = [...cart]
        // console.log(addon)

        const filt = addon.map(ct => {

            if (ct.product?.id == item.id) {
                const quantity = { ...ct }
                quantity.quantity += 1
                // // console.log(quantity)
                return quantity
                // ct.quantity+=1
                // return ct
            }
            else {
                return ct
            }
        })
        dispatch(updateCart(filt))
        localStorage.setItem("cart", JSON.stringify(filt))

        // console.log(filt)

    }
    const productDetails = (id) => {
        // console.log("product id list",id)
        navigate(`/product_details/${id}`)
    }
    const handleCopyurl = () =>{
        const url = process.env.REACT_APP_URL+`/product_details/37}`
        navigator.clipboard.writeText(url)
       
    }
    return (
        <Box sx={{ padding: "32px" }}>
            <Box container component={Grid} sx={{}} >

                {product?.filter(item=>item.id!=params.prod).map(item => {
                    return item.status === "publish" && <>
                        <Grid xs={12} md={4} lg={2.4} sm={6} item sx={{ width: { xs: "100%", sm: "100%", md: "100%", padding: "10px" } }}> <Card sx={{ bgcolor: "#fff", width: "100%",height:"100%",display:"flex",flexDirection:"column",justifyContent:"space-between",cursor:"pointer" }}>
                            <Box onClick={() => productDetails(item.id)}>
                                {/* {// console.log(item.cover_details?.original)} */}
                                <CardMedia
                                    sx={{ height: "300px" }}
                                    image={item?.cover_details?.original || prodOne}
                                    title="Prod One"
                                />
                                <CardContent sx={{marginBottom: 'px'}}>
                                    <Typography sx={styles.header} gutterBottom >
                                        {item.name}
                                    </Typography>
                                    <Typography sx={styles.unit} gutterBottom >
                                        {item.unit}
                                    </Typography>
                                    <Box sx={{ display: "flex", gap: "8px" }}>
                                                {/* <Box sx={{ marginTop: "5px", width: "72px" }} component={'img'} src={ayurvedic} /> */}
                                                {/* <Box sx={{ marginTop: "5px", width: "72px" }} component={'img'} src={organic} /> */}

                                            </Box>


                                </CardContent>
                            </Box>
                            <CardActions sx={{width:"100%",padding: "16px"}}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center",width:"100%"  }}>


                                <Box>
                                            {authtoken ? (
                                                <>
                                                    {item.price !== item.selling_price && (
                                                        <Typography sx={{ color: "grey.500", fontWeight: "400", fontSize: "16px", textDecoration: "line-through" }}>
                                                            <CurrencyRupeeIcon sx={{ fontSize: "16px", fontWeight: 400 }} />{item.price}
                                                        </Typography>
                                                    )}
                                                    <Typography sx={styles.sellingPrice} color={"primary"}>
                                                        <CurrencyRupeeIcon sx={{ fontSize: "16px", fontWeight: 600 }} />{item.selling_price}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <Typography sx={styles.sellingPrice} color={"primary"}>
                                                    <CurrencyRupeeIcon sx={{ fontSize: "16px", fontWeight: 600 }} />{item.price}
                                                </Typography>
                                            )}
                                        </Box>
                                    {
                                                cartCheck(item.id) ? <Box  sx={{ display: "flex",height:"40px", justifyContent: "space-between", gap: "4px", width: "150px", bgcolor: "primary.light",  color: "#fff", padding: "8px", borderTopRightRadius: "20px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", }}>
                                                    <Typography onClick={(e) => updateQuantity(item, false, e)} sx={{ fontSize: "14px", fontWeight: "600", cursor: "pointer",padding:"0px 8px" }}>
                                                       <RemoveIcon sx={{fontSize:"15px",color:"#fff"}} />
                                                    </Typography>

                                                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                                                        {cartCheck(item.id)}
                                                    </Typography>
                                                    <Typography  onClick={(e) => updateQuantity(item, true, e)} sx={{ fontSize: "14px", fontWeight: "600", cursor: "pointer",padding:"0px 8px" }}>
                                                        <AddIcon  sx={{fontSize:"15px",color:"#fff"}} />
                                                    </Typography>

                                                </Box> : item.inventry_details.available_quantity>0?<Button onClick={(e) => addCart(item, 1, e)} sx={[{textTransform:"none",
        border: "0.5px solid rgba(105, 189, 255, 1)", borderTopLeftRadius: 20,
        height:"40px",
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20, "&:hover": { bgcolor: "rgba(105, 189, 255, 1)", color: "#fff" }, display: "flex", alignItems: "center", gap: "8px", padding: "8px", fontSize: "14px",fontWeight:"600" ,width:"150px"}, ]} vatiant="outlined"><ShoppingBasketIcon sx={{ fontSize: "14px", fontWeight: "600" }} /> Add to Cart </Button>:<Box>Out Of Stock</Box>
                                            }
                                </Box>
                            </CardActions>
                        </Card>
                        </Grid>

                    </>

                })}


            </Box>
        </Box>
    )

}
export default Cards