import React from 'react';
import { Box, Typography, Container, Grid, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import notfound from "./assets/notfound.png"
import { Link } from 'react-router-dom';

const variants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const NotFoundPage = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Grid container alignItems="center" alignContent="center" sx={{ minHeight: '100vh',height:"100%" }}>
        <Grid item xs={12} sx={{ textAlign: 'center',mb:"43px" }}>
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
          >
            <Box component={'img'} src={notfound} width={"500px"} height={"500px"} />
          </motion.div>
        </Grid>
        <Grid item xs={12} sx={{textAlign:"center",mb:"10px"}}>
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.7 }}
          >
            <Typography color="#ED6C32" sx={{fontFamily:"poppins",fontSize:"36px",fontWeight:600}}>
              Explore Our <a href="/products" style={{color:"inherit"}} >Ayurvedic Products.</a>
            </Typography>
          </motion.div>
        </Grid>
        <Grid item xs={12} sx={{textAlign:"center"}}>
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.9 }}
          >
            <Typography variant="body1" color="textSecondary">
              The page you requested is missing.
            </Typography>
          </motion.div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFoundPage;