import React, { useState, useEffect } from "react";
import { ExpandMore, ChevronRight, DragIndicator, Close, Search } from "@mui/icons-material";
import { Box, Typography, Paper, Modal, Stack, IconButton, Button, Snackbar, TextField, InputAdornment, Tooltip } from "@mui/material";

import CustomAxios  from "../../utils/CustomAxios";
import "./transferReferalModel.css";
import { useDispatch, useSelector } from "react-redux";
import { updateIsProfileReloaded, updateTranferReferralclose } from "../../redux/user_reducer";
// import { id } from "intl-tel-input/i18n";

const TransferReferalModel = ({ userresponse, open, handleClose }) => {
    const [tree1, setTree1] = useState([]);
    
    const [tree2, setTree2] = useState([]);
    const id=useSelector(state=>state.user.id)
    const [initialTree1, setInitialTree1] = useState("");
    const [initialTree2, setInitialTree2] = useState("");

    const [searchedNode, setSearchedNode] = useState("");

    const [draggedItem, setDraggedItem] = useState(null);
    const [treeDragged, setTreeDragged] = useState(null);
    const [draggedNode, setDraggedNode] = useState(null);

    const [username, setUsername] = useState(""); // State for search bar input
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info"); // Severity: "info", "success", "error"
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const dispatch=useDispatch()
    const transferReferalClose=useSelector(state=>state.user.tranferReferralclose) 
    useEffect(() => {
        const fetchGenerations = async () => {
            try {
                const response = await CustomAxios.post("view-generations/", {
                    user_id: userresponse?.user?.id,
                });
                setTree1(response.data.generations);
                setInitialTree1(JSON.stringify(response.data.generations));
                setTree2([]);
                setInitialTree2("");
            } catch (error) {
                console.error("Error fetching generations:", error);
            }
        };

        if (open) fetchGenerations();
    }, [userresponse, open]);

    const handleDragStart = (item, treeName) => {
        setDraggedItem(item);
        setTreeDragged(treeName);
        setDraggedNode(item.id);
    };

    const isDescendant = (targetNode, draggedItem, treeName) => {

        if(draggedItem.id == searchedNode && treeName=="tree2") {
            return true;
        }

        const checkChildren = (children) => {
            for (let child of children) {
                if (child.id === targetNode.id || (searchedNode == child.id && treeName=="tree2")) {
                    return true;
                }
                if (child.children && checkChildren(child.children)) {
                    return true;
                }
            }
            return false;
        };
        return draggedItem.children ? checkChildren(draggedItem.children) : false;
    };

    const handleDrop = (targetNode, setTree, treeName) => {
        if (draggedItem.id === targetNode.id) {
            showSnackbar("Alert: Transfers between the same account are not allowed.", "error");
            return; // Exit without making any changes
        }
        // Check if the target node is a descendant of the dragged item (to prevent cycles)
        if (isDescendant(targetNode, draggedItem, treeName)) {
            showSnackbar("Alert: A member cannot be transferred to one of their downlines.", "error");
            
            return; // Exit without making any changes
        }

        // Restrict dropping to the root level of the search tree

        // Restrict dropping to the root level of the search tree
        // const isRootLevel = treeName === "tree2" && tree2.some((node) => node.id === targetNode.id);
        // if (!isRootLevel) {
        //     showSnackbar(
        //         `${draggedItem.name} can only be dropped under the root level of the search tree.`,
        //         "error"
        //     );
        //     return;
        // }

        if (draggedItem) {
            // if(treeName == "tree1"){
            //     setTree((prevTree) =>
            //         prevTree
            //             .map((node) => {
                            
            //                 // If the node is the target node and it's not the dragged node itself
            //                 if (node.id === targetNode.id && draggedNode !== targetNode.id) {
                                
            //                     return {
            //                         ...node,
            //                         children: [...(node.children || []), draggedItem],
            //                         expanded: true, // Ensure the target node is expanded
            //                     };
            //                 }
                
            //                 // If the node has children, recursively update the children
            //                 if (node.children) {
            //                     return {
            //                         ...node,
            //                         children: node.children
            //                             .map((childNode) => {
            //                                 // If the child node is the target node, append the dragged item
            //                                 if (childNode.id === targetNode.id && draggedNode !== targetNode.id) {
            //                                     return {
            //                                         ...childNode,
            //                                         children: [...(childNode.children || []), draggedItem],
            //                                         expanded: true,
            //                                     };
            //                                 }
                
            //                                 // Recursively process the child's children
            //                                 if (childNode.children) {
            //                                     return {
            //                                         ...childNode,
            //                                         children: childNode.children.map((grandChildNode) => {
            //                                             if (
            //                                                 grandChildNode.id === targetNode.id &&
            //                                                 draggedNode !== targetNode.id
            //                                             ) {
            //                                                 return {
            //                                                     ...grandChildNode,
            //                                                     children: [...(grandChildNode.children || []), draggedItem],
            //                                                     expanded: true,
            //                                                 };
            //                                             }
            //                                             return grandChildNode;
            //                                         }),
            //                                     };
            //                                 }
                
            //                                 return childNode;
            //                             })
            //                             .filter((childNode) => childNode.id !== draggedItem.id), // Ensure the dragged item is removed from nested levels
            //                     };
            //                 }
                
            //                 // Return the node as is if no changes are needed
            //                 return node;
            //             })
            //             .filter((node) => node.id !== draggedItem.id) // Remove the dragged item from the root-level nodes
            //     );
                
                
            // }

            if (treeName == "tree1") {
                setTree((prevTree) => {
                    // Step 1: Remove the dragged item from its original location
                    const updatedTree = removeNode(prevTree, draggedItem.id);
            
                    // Step 2: Add the dragged item to the target node
                    const updatedTreeWithItem = addNodeToTarget(updatedTree, targetNode.id, draggedItem);
            
                    return updatedTreeWithItem;
                });
            }

            else if(treeName == "tree2") {
                setTree((prevTree) => {
                    // Step 1: Remove the dragged item from its original location
                    const updatedTree = removeNode(prevTree, draggedItem.id);
            
                    // Step 2: Add the dragged item to the target node
                    const updatedTreeWithItem = addNodeToTarget(updatedTree, targetNode.id, draggedItem);
            
                    return updatedTreeWithItem;
                });
                // ---------------- OLD LOGIC --------------
                // setTree((prevTree) =>
                //     prevTree.map((node) => {
                        
                //         if (treeDragged != treeName && node.id === targetNode.id) {
                //             return {
                //                 ...node,
                //                 children: [...(node.children || []), draggedItem],
                //                 expanded: true,
                //             };
                //         }
                //         return node;
                //     })
                // );
            }
            
            const exists = tree1.some((node) => {
                return node.id === draggedItem.id;
            });
            
            // Remove the dragged item from its original tree
            if ( (treeName=="tree2") && (draggedNode != targetNode.id) && (tree1.some((node) => node.id === draggedItem.id))) {
                setTree1((prevTree) => removeNode(prevTree, draggedItem.id));
            } 
            // else if (tree2.some((node) => node.id === draggedItem.id)) {
            //     console.log("check6----------")
            //     setTree2((prevTree) => removeNode(prevTree, draggedItem.id));
            // }

            // Show success snackbar message
            showSnackbar(
                // `${draggedItem.name} successfully dropped under ${targetNode.name}`,
               ` Success! Transfer Complete: ${draggedItem.name} has been successfully transferred to ${targetNode.name}`,
                "success"
            );
           dispatch(updateTranferReferralclose(true))
            setDraggedItem(null); // Clear the dragged item
        }
    };


    const addNodeToTarget = (tree, targetId, nodeToAdd) => {
        return tree.map((node) => {
            if (node.id === targetId) {
                // Add the dragged item as a child of the target node
                return {
                    ...node,
                    children: [...(node.children || []), nodeToAdd],
                    expanded: true, // Ensure the target node is expanded
                };
            }
            if (node.children) {
                // Recursively process children
                return {
                    ...node,
                    children: addNodeToTarget(node.children, targetId, nodeToAdd),
                };
            }
            return node;
        });
    };
    

    const removeNode = (tree, id) => {
        return tree
            .map((node) => {
                if (node.id === id) {
                    return null;
                }
                if (node.children) {
                    return {
                        ...node,
                        children: removeNode(node.children, id),
                    };
                }
                return node;
            })
            .filter((node) => node !== null);
    };

    // const toggleExpand = (nodeId, tree) => {
    //     return tree.map((node) => {
    //         if (node.id === nodeId) {
    //             return { ...node, expanded: !node.expanded };
    //         }
    //         if (node.children) {
    //             return {
    //                 ...node,
    //                 children: toggleExpand(nodeId, node.children),
    //             };
    //         }
    //         return node;
    //     });
    // };

    const handleToggleExpand = (nodeId, setTree) => {
        setTree((prevTree) => toggleExpand(nodeId, prevTree));
    };
    

    const toggleExpand = (nodeId, tree) => {
        return tree.map((node) => {
            if (node.id === nodeId) {
                // Toggle the `expanded` state for the clicked node
                return { ...node, expanded: !node.expanded };
            }
            if (node.children) {
                // Recursively check and update children
                return { ...node, children: toggleExpand(nodeId, node.children) };
            }
            return node; // Return unchanged node
        });
    };

    const handleToggleExpandRoot = (nodeId, setTree) => {
        setTree((prevTree) => toggleRootExpand(nodeId, prevTree));
    };
    

    const toggleRootExpand = (nodeId, tree) => {
        return tree.map((node) => {
            if (node.id === nodeId) {
                // Toggle only the clicked root node
                return { ...node, expanded: !node.expanded };
            }
            // Collapse all other root nodes
            return { ...node, expanded: false };
        });
    };
    


    // const renderTree = (nodes, setTree, draggable, treeName) => {
    //     return nodes.map((node) => (
    //         <Paper
    //             key={node.id}
    //             elevation={3}
    //             sx={{
    //                 margin: "8px 0",
    //                 padding: 1,
    //                 borderRadius: "8px",
    //                 backgroundColor: "#f9f9f9",
    //                 display: "flex",
    //                 alignItems: "center",
    //                 gap: 1,
    //                 width: "100%",
    //                 overflow: "auto",
    //                 border: "1px solid #ddd",
    //                 "&:hover": { boxShadow: 4, backgroundColor: "#f0f8ff" },
    //             }}
    //         >
    //             <Tooltip
    //                 title={
    //                     (node.not_transferred || !draggable)
    //                         ? "" // No tooltip if transferable
    //                         : "You cannot transfer this user since you are not the introducer"
    //                 }
    //                 arrow
    //                 disableHoverListener={node.not_transferred} // Disable tooltip for transferable nodes
    //             >
    //             <Box
    //                 sx={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     gap: 1,
    //                     // flexGrow: 1,
    //                     cursor: "pointer",
    //                 }}
    //                 draggable={node.not_transferred  && draggable}
    //                 onDragStart={() => handleDragStart(node, treeName)}
    //                 onDragOver={(e) => e.preventDefault()}
    //                 onDrop={() => handleDrop(node, setTree, treeName)}
    //             >
    //                 {node.children && node.children.length > 0 && (
    //                     <IconButton
    //                         onClick={() =>
    //                             setTree((prevTree) => toggleExpand(node.id, prevTree))
    //                         }
    //                         size="small"
    //                     >
    //                         {node.expanded ? <ExpandMore /> : <ChevronRight />}
    //                     </IconButton>
    //                 )}
    //                 <Typography variant="body1" sx={{ fontWeight: "bold", color: "#333" }}>
    //                     {node.name}
    //                 </Typography>
    //             </Box>
    //             </Tooltip>
    //             {(node.not_transferred  && draggable) && (
    //                 <DragIndicator sx={{ color: "#aaa", cursor: "grab" }} fontSize="small" />
    //             )}
    //             {node.children && node.expanded && (
    //                 <Box sx={{ paddingLeft: "20px", marginTop: "4px" }}>
    //                     {renderTree(node.children, setTree, false, treeName)}
    //                 </Box>
    //             )}
    //         </Paper>
    //     ));
    // };



    
    const renderTree = (nodes, setTree, draggable, treeName, isRoot = false) => {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}>
                {nodes.map((node) => (
                    <Box
                        key={node.id}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            position: "relative",
                            paddingLeft: isRoot ? "0px" : "12px",
                        }}
                    >
                        {/* Parent Node */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                backgroundColor: "#f9f9f9",
                                padding: "4px 8px",
                                borderRadius: "4px",
                                border: "1px solid #ddd",
                                fontSize: "12px",
                                cursor: node.not_transferred && draggable ? "grab" : "not-allowed",
                                "&:hover": { backgroundColor: "#f0f8ff" },
                            }}
                            draggable={node.not_transferred && draggable}
                            onDragStart={() => handleDragStart(node, treeName)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={() => handleDrop(node, setTree, treeName)}
                        >
                            {node.children && node.children.length > 0 && (
                                <IconButton
                                    onClick={() =>
                                        isRoot
                                            ? handleToggleExpandRoot(node.id, setTree)
                                            : handleToggleExpand(node.id, setTree)
                                    }
                                    size="small"
                                    sx={{ color: isRoot ? "#2e7d32" : "#333" }}
                                >
                                    {node.expanded ? <ExpandMore /> : <ChevronRight />}
                                </IconButton>
                            )}
                            <Tooltip
                                title={
                                    (node.not_transferred || !draggable)
                                        ? "" // No tooltip if transferable
                                        : `Action Restricted: You are not the introducing sponsor for ${node.name} and cannot initiate this transfer.`
                                }
                                arrow
                                disableHoverListener={node.not_transferred} // Disable tooltip for transferable nodes
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: isRoot ? "bold" : "normal",
                                        color: isRoot ? "#2e7d32" : "#333", // Blue for root, default gray for others
                                        fontSize: "12px",
                                    }}
                                >
                                    {node.name}
                                </Typography>
                            </Tooltip>
                            {/* {(node.not_transferred && draggable) && (
                                <DragIndicator
                                    sx={{ color: "#aaa", cursor: "grab" }}
                                    fontSize="small"
                                />
                            )} */}
                        </Box>
    
                        {/* Children Nodes */}
                        {node.children && node.expanded && (
                            <Box sx={{ marginTop: "4px", paddingLeft: "16px" }}>
                                {renderTree(node.children, setTree, false, treeName)}
                            </Box>
                        )}
                    </Box>
                ))}
            </Box>
        );
    };
    
    

    const handleSearch = async () => {
        if (!username.trim()) {
            // Validation: Empty input
            showSnackbar("Username cannot be empty", "error");
            return;
        }
        
        if (username.toLowerCase() == userresponse?.user?.username.toLowerCase()) {
            showSnackbar("Alert: You cannot search for the currently logged-in member.", "error");
            return;
        }

        try {
            const response = await CustomAxios.post("search-user/", {
                username: username.trim(),
            });

            if (response.data) {
                // Success: Fetching tree for the username
                setTree2(response.data.generations); // Assuming `tree` comes from the API
                setInitialTree2(JSON.stringify(response.data.generations));
                setSearchedNode(response.data.generations ? response.data.generations[0]?.id : "");

                showSnackbar("User fetched successfully", "success");
            } else {
                // Error: Backend message
                showSnackbar(response.data.message || "User not found", "error");
            }
        } catch (error) {
            // Error: Backend failure
            showSnackbar(
                error.response?.data?.message || "Username not found. please try again",
                "error"
            );
        }
    };

    const handleSave = async () => {
        const payload = {};

        const removeExpanded = (tree) => {
            return tree.map((node) => {
                const { expanded, ...rest } = node;
                return {
                    ...rest,
                    children: node.children ? removeExpanded(node.children) : [],
                };
            });
        };
    
        // Cleaned trees for comparison
        const cleanedTree1 = removeExpanded(tree1);
        const cleanedInitialTree1 = removeExpanded(JSON.parse(initialTree1));
    
        // Check if Tree 1 has changed
        if (JSON.stringify(cleanedTree1) !== JSON.stringify(cleanedInitialTree1)) {
            payload.generations = tree1;
        }

        // Check if Tree 2 has changed and is not empty
        if (tree2.length > 0 && JSON.stringify(tree2) !== initialTree2) {
            payload.second_generations = tree2;
        }

        if (!payload.generations && !payload.second_generations) {
            showSnackbar("No changes to save", "info");
            return;
        }
        payload.id=id
        try {
            const response = await CustomAxios.post("save-generations/", payload);

            if (response.data.success) {
                showSnackbar("Your changes has been made successfully!", "success");
                setTimeout(() => handleClose(false), 1000);
                // dispatch(updateTranferReferralclose(false))
                
                 // Close the modal after saving
            } else {
                showSnackbar(response.data.message || "Failed to save data.", "error");
            }
        } catch (error) {
            showSnackbar(
                error.response?.data?.message || "An error occurred while saving data.",
                "error"
            );
        }
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
        setTimeout(() => setSnackbarOpen(false), 3000); // Auto-close after 3 seconds
    };

    return (
        <Modal
            open={open}
            onClose={()=>handleClose(transferReferalClose)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            sx={{zIndex:"21 !important"}}
        >
           
            <Paper sx={{background: "#fff", left: "50%", top: "50%", position: "absolute", transform: 'translate(-50%, -50%)', padding: "32px", border: "none" ,width:"80%"  }}>
                
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Transfer Team Member
                    </Typography>
                    <IconButton onClick={()=>handleClose(transferReferalClose)}>
                        <Close />
                    </IconButton>
                </Box>
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: "space-between",
                        gap: "16px",
                        marginTop: "16px",
                        flexWrap: "wrap",
                        // overflowY:"scroll",
                        // height:"80%"
                    }}>
                    <Box
                        sx={{
                            // flex: 1,
                            minWidth: {xs:"100%",sm: "300px"},
                            padding: "16px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            backgroundColor: "#f8f9fa",
                            height:{xs:"315px",sm:"400px"},
                            overflowY:"auto"
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", color: "#333", marginBottom: "8px" }}
                        >
                            {userresponse?.user?.username || "Tree 1"}
                        </Typography>
                        {renderTree(tree1, setTree1, true, "tree1", true)}
                    </Box>
                    
                    <Box
                        sx={{
                            flex: 1,
                            minWidth: {sm: "300px"},
                            padding: "16px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            backgroundColor: "#f8f9fa",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", color: "#333", marginBottom: "16px" }}
                        >
                            Transfer to
                        </Typography>

                        {/* Search Bar */}
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Enter Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleSearch} edge="end">
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ marginBottom: "16px" }}
                        />

                        {/* Render Tree */}
                        {renderTree(tree2, setTree2, false, "tree2", true)}

                        {/* Snackbar */}
                        <Snackbar
                            open={isSnackbarOpen}
                            onClose={() => setSnackbarOpen(false)}
                            message={snackbarMessage}
                            autoHideDuration={3000}
                            action={
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => setSnackbarOpen(false)}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            }
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            ContentProps={{
                                sx: {
                                    backgroundColor:
                                        snackbarSeverity === "success"
                                            ? "green"
                                            : snackbarSeverity === "error"
                                            ? "red"
                                            : snackbarSeverity === "warning"
                                            ? "yellow"
                                            : "blue",
                                    color: "#fff",
                                },
                            }}
                        />
                    </Box>


                </Stack>
                <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "16px" }}>
                    <Button
                            variant="contained"
                            onClick={handleSave}
                            sx={{
                                backgroundColor: "#007bff",
                                color: "#fff",
                                textTransform: "none",
                                "&:hover": {
                                    backgroundColor: "#0056b3",
                                },
                            }}
                        >
                        Save & Close
                    </Button>
                    {/* <Button
                        variant="contained"
                        onClick={handleClose}
                        sx={{
                            backgroundColor: "#007bff",
                            color: "#fff",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#0056b3",
                            },
                        }}
                    >
                        Close
                    </Button> */}
                </Box>
            </Paper>
        </Modal>
    );
};

export default TransferReferalModel;
